/*
 * @Author: Yin Xiang Zheng
 * @LastEditors: Yin Xiang Zheng
 * @LastEditTime: 2021-02-14 17:13:14
 */
import request from "@/api/request";
// 创建业委会成员
export function createUOCUser(data) {
  return request({
    url: "/api/uoc/user",
    method: "post",
    data,
  });
}
// 创建业委会成员 单个
export function createUOCUserSingle(data) {
  return request({
    url: "/api/uoc/user/single",
    method: "post",
    data,
  });
}

// 修改 业委会成员
export function updateUOCUser(id, data) {
  return request({
    url: `/api/uoc/user/${id}`,
    method: "put",
    data,
  });
}


// 获取业委会成员
export function getUOCUsers() {
  return request({
    url: "/api/uoc/users",
    method: "get",
  });
}
// 获取职位
export function getUOCPositions() {
  return request({
    url: "/api/uoc/position",
    method: "get",
  });
}

// 删除业委会成员
export function deleteUOCUser(_id) {
  return request({
    url: `/api/uoc/user/${_id}`,
    method: "delete",
  });
}

// 获取备案信息劣币啊
export function getRecordInfo() {
  return request({
    url: "/api/uoc/recordInformation",
    method: "get",
  });
}

// 获取业委会值班表
export function getDutyList() {
  return request({
    url: "/api/uoc/dutyList",
    method: "get",
  });
}

// 创建业委会值班表
export function putDutyList(data) {
  return request({
    url: "/api/uoc/dutyList",
    method: "put",
    data,
  });
}

// 更新 小区详细信息
export function putUOCDetails(data) {
  return request({
    url: "/api/uoc/details",
    method: "put",
    data,
  });
}

// 获取 小区详细信息
export function getUOCDetails() {
  return request({
    url: "/api/uoc/details",
    method: "get",
  });
}


// 查看单个手机号 showPhone
export function showPhone(id) {
  return request({
    url: `/api/communityInformation/building/phone/${id}`,
    method: "get",
  });
}

// 更新单条产业信息
export function editBigFloorDetail(id, data) {
  return request({
    url: `/api/communityInformation/building/${id}`,
    method: "put",
    data
  });
}

